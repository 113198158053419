import Coin from "@comps/containers/Coin";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import divider from "@img/divider_horizontal_small.png";
import { TransitionProps } from "@lib/CSStransition";

const mcs = makeStyles({
  BODY: {
    width: 1920,
    height: 1080,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  BG: {
    backgroundColor: "rgba(0,0,0,.5)",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
  },
  SCHOOL: {
    fontFamily: `"CINZEL bold"`,
    marginTop: 30,
    color: "#fff",
    fontSize: 60,
    lineHeight: 1,
  },
  ORG: {
    color: "#fff",
    fontSize: 30,
    lineHeight: 1,
    textTransform: "uppercase",
  },
  WIN: {
    color: "#f9cd00",
    fontSize: 40,
    lineHeight: 1,
    textTransform: "uppercase",
  },
  DIVIDER: {
    height: 14,
    width: 200,
    margin: 15,
    backgroundImage: `url("${divider}")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  WRAPPER: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
  },
});

const Winner = () => {
  const CLASSES = mcs();
  const { match_winner } = useSelector<ReduxState, Live>((s) => s.live);
  const LIVE = match_winner?.live;
  const TEAM = match_winner?.team;

  const body = TransitionProps(!!LIVE && !!TEAM, {
    duration: 1000,
    props: (on) => ({
      opacity: on ? 1 : 0,
    }),
  });
  const content = TransitionProps(!!LIVE && !!TEAM, {
    duration: 1000,
    props: (on) => ({
      opacity: on ? 1 : 0,
      transform: `translateY(${on ? 0 : -15}px)`,
    }),
  });
  const coin = TransitionProps(!!LIVE && !!TEAM, {
    duration: 1000,
    props: (on) => ({
      opacity: on ? 1 : 0,
      transform: `rotate(${on ? 0 : 360}deg) scale(${on ? 1 : 1.2})`,
    }),
  });

  return (
    <div className={CLASSES.BODY} style={body}>
      <div className={CLASSES.BG}></div>
      <div className={CLASSES.WRAPPER} style={content}>
        <Coin
          url={TEAM?.logo}
          base64={TEAM?.logo_base64}
          color={TEAM?.badge_color}
          style={coin}
        />
        <div className={CLASSES.SCHOOL}>{TEAM?.university_acronym}</div>
        <div className={CLASSES.ORG}>{TEAM?.org_shortname}</div>
        <div className={CLASSES.DIVIDER}></div>
        <div className={CLASSES.WIN}>WIN</div>
      </div>
    </div>
  );
};

export default Winner;
