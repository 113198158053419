import { makeStyles } from "@material-ui/core";
import React from "react";
import { HTMLAttributes } from "react";
import coinBase from "@img/coin_base.png";
import coinGlare from "@img/coin_glare.png";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";

interface CoinProps extends HTMLAttributes<HTMLDivElement> {
  url?: string;
  base64?: string;
  color?: string;
  shadow?: boolean;
  disableGlare?: boolean;
  overlayProps?: HTMLAttributes<HTMLDivElement>;
}
//f5a814 - yellow
//0d3ae0 - blue
const mcs = makeStyles<any, Partial<CoinProps>>({
  BODY: {
    width: 284,
    height: 284,
    borderRadius: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    backgroundImage: `url("${coinBase}")`,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    transition: "all 0.6s ease-in-out",
    boxShadow: (props) => `inset 0px -5px ${props.color || "#e7c314"}`,
    "&::before": {
      content: `''`,
      boxShadow: (props) =>
        `0px 15px 10px rgba(0,0,0,${props.shadow ? 0.5 : 0})`,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      display: "flex",
      zIndex: 1,
    },
  },
  OVERLAY: {
    display: "flex",
    width: "100%",
    height: "100%",
    transform: "scale(0.98)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 50,
    borderRadius: "100%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("${coinGlare}")`,
    mixBlendMode: "hard-light",
  },

  LOGO: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    height: "60%",
    width: "60%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
});

const Coin = ({
  url = "",
  base64,
  overlayProps,
  disableGlare = false,
  className,
  color,
  shadow = false,
  children,
  ...props
}: CoinProps) => {
  const CLASSES = mcs({ shadow, url, base64, color });
  return (
    <div className={`${className} ${CLASSES.BODY}`} {...props}>
      {!disableGlare && (
        <div className={CLASSES.OVERLAY} {...overlayProps}></div>
      )}
      <div
        className={CLASSES.LOGO}
        style={{ backgroundImage: `url("${base64 || url}")` }}
      ></div>
    </div>
  );
};

export default Coin;
