import PlayerCard from "@comps/containers/PlayerCard";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  BODY: {
    display: "flex",
  },
});

const Lodi = () => {
  const CLASSES = mcs();
  const { logitech_mvp } = useSelector<ReduxState, Live>((state) => state.live);

  const name = logitech_mvp?.ign;
  const team = logitech_mvp?.team;
  const agent = logitech_mvp?.agent;
  const photo = logitech_mvp?.photo_main;
  const photoSettings = logitech_mvp?.player_adjustments;
  const CUSTOM_BG = logitech_mvp?.champion;
  const characterSettings = logitech_mvp?.character_adjustments;

  return (
    <div className={CLASSES.BODY}>
      <div className="">
        <PlayerCard
          PLAYER_NAME={name}
          PLAYER_IMAGE={photo}
          TEAM={team}
          IMAGE_SETTINGS={photoSettings}
          AGENT={agent}
          CUSTOM_BG={CUSTOM_BG}
          CUSTOM_BG_SETTINGS={characterSettings}
        />
      </div>
    </div>
  );
};

export default Lodi;
