import { TimelineItem, TimerlineSlider } from "@defs/timeline.interface";
import { makeStyles } from "@material-ui/core";
import { FC, HTMLProps } from "react";
import SuperContainer from "./SuperContainer";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    paddingRight: 20,
  },
  items: {
    flex: 1,
    height: "100%",
    display: "grid",
  },
  item: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    paddingBottom: 15,
    fontWeight: "bold",
    "& .bottom-text": {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 20,
      height: 40,
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      justifyItems: "center",
    },
  },
  bar: {
    flexShrink: 0,
    height: 7,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    padding: "10px 0",

    "& .inner": {
      position: "absolute",
      height: 7,
      width: "100%",
      backgroundColor: "#f2be09",
      transform: "translateX(-50%)",
      "& .progress": {
        backgroundColor: "#ea3a5a",
        height: "100%",
        width: "100%",
        willChange: "clip-path",
        transitionProperty: "all",
        transitionDuration: "600ms",
      },
    },
  },

  dot: {
    height: 20,
    width: 20,
    borderRadius: 10,
    position: "absolute",
    backgroundColor: "#f2be09",
    zIndex: 10,
    transition: "all 600ms",
  },
  startItem: {
    width: 100,
    flexShrink: 0,
  },

  itemDetails: {
    width: "100%",
    flex: 1,
    padding: "0px 10px",
    display: "flex",
    alignItems: "flex-end",

    "& .image": {
      flex: 1,
      minWidth: 100,
      height: "100%",
      backgroundSize: "contain",
      backgroundPosition: "right center",
      backgroundRepeat: "no-repeat",
    },

    "& .text": {
      padding: "0px 10px",
      color: "#f2be09",
      fontSize: 20,
      height: "100%",
      paddingBottom: 20,
      paddingTop: 40,
      lineHeigth: 1,
      display: "flex",
      alignItems: "center",
    },
  },
});

const SuperTimeline: FC<{
  props: HTMLProps<HTMLDivElement>;
  timeline?: TimerlineSlider;
}> = ({ props, timeline }) => {
  const c = useStyles();

  const items = timeline?.items ?? [];
  const progress = timeline?.progress ?? 0;

  const smartMove = (item: TimelineItem, index: number) => {
    const prevValue = items[index - 1]?.value ?? 0;
    const curr = item.value;
    const prev = prevValue < progress ? progress : 0;

    const percent = (prev / curr) * 100;
    return percent > 100 ? 100 : percent;
  };

  return (
    <SuperContainer {...props}>
      <div className={c.wrapper}>
        <div className={`${c.item} ${c.startItem}`}>
          <div className="bottom-text"></div>
          <div
            className={c.bar}
            style={{ justifyContent: "flex-end", transform: "translateX(2px)" }}
          >
            <div
              className={c.dot}
              style={{ backgroundColor: "#ea3a5a", zIndex: 20 }}
            ></div>
          </div>
        </div>
        <div
          className={c.items}
          style={{
            gridTemplateColumns: `repeat(${items.length}, minmax(0, 1fr))`,
          }}
        >
          {items.map((item, i) => (
            <div className={c.item} key={i}>
              <div className="bottom-text">{item.main.text}</div>
              <div className={c.bar}>
                <div
                  className="inner"
                  style={{ width: i === 0 ? "50%" : "100%" }}
                >
                  <div
                    className="progress"
                    style={{
                      clipPath: `polygon(
                        0% 0%,
                        0% 100%,
                        ${smartMove(item, i)}% 100%,
                        ${smartMove(item, i)}% 0%
                      )`,
                      transitionDelay: `${600 * i}ms`,
                    }}
                  ></div>
                </div>
                <div
                  className={c.dot}
                  style={{
                    transitionDelay: `${600 * i + 1}ms`,
                    backgroundColor:
                      progress >= item.value ? "#ea3a5a" : "#f2be09",
                  }}
                ></div>
              </div>

              <div className={c.itemDetails}>
                <div
                  className="image"
                  style={{ backgroundImage: `url("${item.opposite?.image}")` }}
                ></div>
                <div className="text">{item.opposite?.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SuperContainer>
  );
};

export default SuperTimeline;
