import useTournament from "@lib/useTournament";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ScheduleMatch } from "../ScheduleMatch";
import divider from "@img/divider_vertical.png";

const mcs = makeStyles({
  BODY: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },

  SECTION: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: "0px 20px",
    position: "relative",
    maxWidth: 389.5,
  },
  DIVIDER: {
    height: 479,
    width: 15,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundImage: `url("${divider}")`,
  },
  LABEL: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#fff",
    opacity: 0.8,
    fontSize: "1.2em",
    padding: "12px 20px",
  },
});

const Schedule = () => {
  const CLASSES = mcs();
  const { matches_today: matches = [] } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { sortMatches } = useTournament();
  const sorted = sortMatches(matches);
  const maximized = sorted.filter((_, i) => i < 6);
  const leftMatches = maximized.filter((_, i) => i < 3);
  const rightMatches = maximized.filter((_, i) => i >= 3);
  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.LABEL}>SCHEDULE</div>

      <div className={CLASSES.SECTION}>
        {leftMatches.map((match, i) => (
          <ScheduleMatch
            key={i}
            {...{
              match,
              iconSize: 115,
              nameSize: 28,
              dividerHeight: 10,
              scheduleSize: 16,
              showWinner: true,
              middleMarginY: 8,
            }}
          />
        ))}
      </div>
      {maximized.length > 3 && (
        <>
          <div className={CLASSES.DIVIDER}></div>
          <div className={CLASSES.SECTION}>
            {rightMatches.map((match, i) => (
              <ScheduleMatch
                key={i}
                {...{
                  match,
                  iconSize: 115,
                  nameSize: 28,
                  dividerHeight: 10,
                  showWinner: true,
                  scheduleSize: 16,
                  middleMarginY: 8,
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Schedule;
