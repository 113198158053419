import TeamNameTag from "@comps/containers/TeamNameTag";
import useTournament from "@lib/useTournament";
import { TransitionProps } from "@lib/CSStransition";
import { makeStyles } from "@material-ui/core";

const mcs = makeStyles({
  body: {
    width: 1920,
    height: 1080,
    position: "relative",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  lowerThirds: {
    display: "flex",
    position: "absolute",
    bottom: 151,
  },
  casters: {
    display: "flex",

    position: "absolute",
    bottom: 283,
  },
  vs: {
    fontFamily: `"Cinzel bold"`,
    width: 103,
    textAlign: "center",
    lineHeight: 1,
    fontSize: 45,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const CasterCam = () => {
  const c = mcs();
  const { team, match } = useTournament();
  const player1_id = match?.player1_id;
  const player2_id = match?.player2_id;
  const team1 = team(player1_id);
  const team2 = team(player2_id);

  const style = TransitionProps(!!team && !!match, {
    introDelay: 600,
    easing: "cubic-bezier(0.33, 1, 0.68, 1)",
    props: (on) => ({
      transform: `translateY(${on ? 0 : 10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  return (
    <div className={c.body} style={style}>
      <div className={c.lowerThirds}>
        <TeamNameTag team={team1} match={match} />
        <div className={c.vs}>VS</div>
        <TeamNameTag team={team2} match={match} flipped />
      </div>
    </div>
  );
};

export default CasterCam;
