import React from "react";
import ascent from "@assets/maps/ascent.png";
import bind from "@assets/maps/bind.png";
import breeze from "@assets/maps/breeze.png";
import haven from "@assets/maps/haven.png";
import icebox from "@assets/maps/icebox.png";
import split from "@assets/maps/split.png";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Live, ReduxState, VetoItem } from "@defs/types";
import banOutlines from "@img/ban_outlines.png";
import diamond from "@img/paper.jpg";
import bo1Outline from "@img/one_map_outline.png";
import pickOutline from "@img/pick_outline.png";
import Coin from "@comps/containers/Coin";
import { TransitionProps } from "@lib/CSStransition";
import divider from "@img/divider_horizontal_small.png";

const map = {
  ascent,
  bind,
  breeze,
  haven,
  icebox,
  split,
};

const mcs = makeStyles<
  any,
  { bo1?: boolean; bestOf?: number; veto?: VetoItem; posY?: number }
>({
  BODY: {
    width: 1500,
    height: 653,
    display: "grid",
    gridTemplateRows: "repeat(3, minmax(0, 1fr))",
    gridAutoFlow: "column",
    rowGap: 9,
    columnGap: 21,
  },
  BAN: {
    width: 317,
    height: "100%",
    backgroundColor: "#f0f0f0",
    overflow: "hidden",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    "&::before": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 5,
      backgroundImage: `url("${diamond}")`,
      backgroundPosition: (props) => `center ${props.posY}%`,
      backgroundSize: "cover",
      opacity: 0.5,
      transition: "all 0.6s cubic-bezier(0.65, 0, 0.35, 1)",
      mixBlendMode: "hard-light",
    },

    "& .map": {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: (props) => `url("${map[props.veto?.map ?? "ascent"]}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: "grayscale(80%)",
      zIndex: 1,
      position: "relative",

      "&::after": {
        content: `""`,
        position: "absolute",
        height: 147,
        width: 289,
        backgroundImage: `url("${banOutlines}")`,
        backgroundSize: "100% 100%",
        zIndex: 3,
      },
    },

    "& .footer": {
      flexShrink: 0,
      height: 45,
      width: "100%",
      display: "flex",
      padding: "0px 10px 4px 10px",
      alignItems: "center",
      position: "relative",
      "&::after": {
        content: `""`,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 3,
        backgroundColor: "rgba(0,0,0,.2)",
      },
      "& .num": {
        fontFamily: `"CINZEL bold"`,
        transform: "rotate(-90deg) translateX(-3px)",
        fontSize: 20,
        width: 20,
        flexShrink: 0,
      },
      "& .name": {
        fontFamily: `"CINZEL bold"`,
        flexGrow: 1,
        textTransform: "uppercase",
        fontSize: 24,
        paddingLeft: 20,
      },
      "& .logo": {
        height: 40,
        flexShrink: 0,
        width: 40,
        filter: "grayscale(100%)",
        backgroundImage: (props) => `url("${props.veto?.team.logo}")`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginLeft: 20,
      },
    },
  },
  PICK: {
    gridRow: "1 / -1",
    gridColumn: (props) => (props.bo1 ? "2/4" : "auto"),
    backgroundColor: "#f0f0f0",
    overflow: "hidden",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexShrink: 0,
    "&::before": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 5,
      backgroundImage: `url("${diamond}")`,
      backgroundPosition: (props) => `center ${props.posY}%`,
      backgroundSize: "cover",
      opacity: 0.5,
      transition: "all 0.6s cubic-bezier(0.65, 0, 0.35, 1)",
      mixBlendMode: "hard-light",
    },
    "& .map": {
      flexGrow: 1,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: (props) => `url("${map[props.veto?.map ?? "ascent"]}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: 1,
      position: "relative",

      "&::after": {
        content: `""`,
        position: "absolute",
        height: 531,
        opacity: (props) => ((props.bestOf ?? 0) > 3 ? 0 : 1),
        width: ({ bo1 }) => (bo1 ? 776 : 335),
        backgroundImage: (props) =>
          `url("${props.bo1 ? bo1Outline : pickOutline}")`,
        backgroundSize: "100% 100%",
        zIndex: 3,
      },
    },
    "& .footer": {
      flexShrink: 0,
      height: 104,
      width: "100%",
      display: "flex",
      padding: (props) =>
        (props.bestOf ?? 0) > 3 ? "0px 20px 8px 20px" : "0px 40px 8px 40px",
      alignItems: "center",
      position: "relative",
      "&::after": {
        content: `""`,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 8,
        backgroundColor: "rgba(0,0,0,.2)",
      },
      "& .num": {
        fontFamily: `"CINZEL bold"`,
        transform: "rotate(-90deg)",
        fontSize: 20,
        width: 20,
        flexShrink: 0,
      },
      "& .name": {
        fontFamily: `"CINZEL bold"`,
        flexGrow: 1,
        textTransform: "uppercase",
        fontSize: (props) => ((props.bestOf ?? 0) > 3 ? 40 : 55),
        paddingLeft: (props) => ((props.bestOf ?? 0) > 3 ? 0 : 20),
      },
      "& .logo": {
        position: "absolute",
        right: -40,
        transform: "translateY(10px)",
        flexShrink: 0,
        height: 160,
        width: 160,
        opacity: 0.3,
        backgroundImage: (props) => `url("${props.veto?.team.logo}")`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // marginLeft: 20,
      },
    },
  },
  WINNER: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,.5)",
    lineHeight: 1,
    "& .school": {
      fontFamily: `"CINZEL bold"`,
      marginTop: 30,
      color: "#fff",
      fontSize: 50,
    },
    "& .org": {
      color: "#fff",
      fontSize: 17,
      textTransform: "uppercase",
    },
    "& .element": {
      height: 11,
      width: 144,
      margin: 10,
      backgroundSize: "cover",
      backgroundImage: `url("${divider}")`,
      backgroundPosition: "center",
    },
    "& .win": {
      color: "#ffd200",
      fontFamily: `"CINZEL bold"`,
      fontSize: 24,
    },
  },
  COIN: {
    fontFamily: `"CINZEL bold"`,
    height: 150,
    width: 150,
  },
});

const Veto = () => {
  const CLASSES = mcs({});
  const { match } = useSelector<ReduxState, Live>((s) => s.live);
  const VETO = match?.veto ?? [];
  const BEST_OF = match?.bestOf ?? 1;
  return (
    <div
      className={CLASSES.BODY}
      style={{
        gridTemplateColumns:
          BEST_OF === 1
            ? "317px 1fr 317px"
            : !!BEST_OF
            ? `repeat(${BEST_OF}, minmax(0,1fr))`
            : "317px 1fr 1fr 1fr",
      }}
    >
      {VETO.map((veto, i, arr) =>
        veto.type === "ban" ? (
          <Ban veto={veto} key={i} arr={arr} />
        ) : (
          <Pick
            veto={veto}
            key={i}
            arr={arr}
            bo1={BEST_OF === 1}
            bestOf={BEST_OF}
          />
        )
      )}
    </div>
  );
};

const Ban = ({ veto, arr }: { veto: VetoItem; arr: VetoItem[] }) => {
  const CLASSES = mcs({ veto, posY: Math.floor(Math.random() * 40) });
  const num = arr.findIndex((v) => v.map === veto.map) + 1;
  return (
    <div className={CLASSES.BAN}>
      <div className="map"></div>
      <div className="footer">
        <div className="num">B{num}</div>
        <div className="name">{veto.map}</div>
        <div className="logo"></div>
      </div>
    </div>
  );
};

const Pick = ({
  bo1,
  veto,
  arr,
  bestOf,
}: {
  veto: VetoItem;
  bo1?: boolean;
  arr: VetoItem[];
  bestOf?: number;
}) => {
  const CLASSES = mcs({
    bo1,
    veto,
    posY: Math.floor(Math.random() * 80),
    bestOf,
  });
  const num =
    arr.filter((v) => v.type === "pick").findIndex((v) => v.map === veto.map) +
    1;
  return (
    <div className={CLASSES.PICK}>
      <div className="map">
        <div
          className={CLASSES.WINNER}
          style={TransitionProps(!!veto.winner, {
            props: (on) => ({
              opacity: on ? 1 : 0,
            }),
          })}
        >
          <Coin
            url={veto.winner?.logo}
            className={CLASSES.COIN}
            color={veto?.winner?.badge_color}
          />
          <div className="school">{veto.winner?.university_acronym}</div>
          <div className="org">{veto.winner?.org_shortname}</div>
          <div className="element"></div>
          <div className="win">WIN</div>
        </div>
      </div>
      <div className="footer">
        <div className="name">{veto.map}</div>
        <div className="logo"></div>
      </div>
    </div>
  );
};

export default Veto;
