import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import { HTMLAttributes } from "react";
import { FC } from "react";
import { useSelector } from "react-redux";
import card from "@img/card-vertical.png";
import MatchContent from "@comps/contents/small_banner/Match";
import { TransitionProps } from "@lib/CSStransition";
import TimerComponent from "@comps/contents/small_banner/Timer";
import Schedule from "@comps/contents/small_banner/Schedule";

const mcs = makeStyles({
  BODY: {
    width: 381,
    height: 676,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("${card}")`,
    display: "flex",
    flexDirection: "column",
  },
  CONTENT: {
    height: 578,
    width: "100%",
    display: "flex",
    flexShrink: 0,
    position: "relative",
  },
  WRAPPER: {
    height: "100%",
    width: "100%",
    padding: 20,
    position: "absolute",
  },
});
const Match: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  const CLASSES = mcs();
  const { container_mode } = useSelector<ReduxState, Live>((s) => s.live);

  // <------------- ON ENTER ------------->
  const onEnter = !!container_mode;
  const onEnterStyles = TransitionProps(onEnter, {
    props: (on) => ({
      transform: `translateY(${on ? 0 : 10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- WHEN container_mode = "schedule" ------------->
  const onSchedule = container_mode === "schedule";
  const onScheduleStyles = TransitionProps(onSchedule, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : -10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- WHEN container_mode != "schedule" ------------->
  const otherStyles = TransitionProps(!onSchedule, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : 10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  return (
    <div
      className={`${className} ${CLASSES.BODY}`}
      {...props}
      style={onEnterStyles}
    >
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.WRAPPER} style={onScheduleStyles}>
          <MatchContent />
        </div>
        <div className={CLASSES.WRAPPER} style={otherStyles}>
          <Schedule />
        </div>
      </div>
      <TimerComponent />
    </div>
  );
};

export default Match;
