import { Agent, Match, Participant } from "@defs/types";
import useTournament from "@lib/useTournament";
import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";
import outline from "@img/team_banner_outline.png";
import { TransitionProps } from "@lib/CSStransition";
import useAdjustments from "@lib/useAdjustments";
import { AdjustmentsProps } from "@defs/adjustments.interface";
import Astra from "@assets/agents/Astra_artwork.png";
import Breach from "@assets/agents/Breach_artwork.png";
import Brimstone from "@assets/agents/Brimstone_artwork.png";
import Cypher from "@assets/agents/Cypher_artwork.png";
import Jett from "@assets/agents/Jett_artwork.png";
import KAYO from "@assets/agents/KAYO_artwork.png";
import Killjoy from "@assets/agents/Killjoy_artwork.png";
import Omen from "@assets/agents/Omen_artwork.png";
import Phoenix from "@assets/agents/Phoenix_artwork.png";
import Raze from "@assets/agents/Raze_artwork.png";
import Reyna from "@assets/agents/Reyna_artwork.png";
import Sage from "@assets/agents/Sage_artwork.png";
import Skye from "@assets/agents/Skye_artwork.png";
import Sova from "@assets/agents/Sova_artwork.png";
import Viper from "@assets/agents/Viper_artwork.png";
import Yoru from "@assets/agents/Yoru_artwork.png";

const INFO_HEIGHT = 64;
const HEIGHT = 446 - INFO_HEIGHT;
const WIDTH = 250;

const agents: { [key in Agent]: string } = {
  astra: Astra,
  breach: Breach,
  brimstone: Brimstone,
  cypher: Cypher,
  jett: Jett,
  kayo: KAYO,
  killjoy: Killjoy,
  omen: Omen,
  phoenix: Phoenix,
  raze: Raze,
  reyna: Reyna,
  sage: Sage,
  skye: Skye,
  sova: Sova,
  viper: Viper,
  yoru: Yoru,
};

const mcs = makeStyles<any, { posX: number; FLIPPED: boolean; AGENT?: string }>(
  {
    BODY: {
      height: HEIGHT + 64,
      width: WIDTH,
      position: "relative",
      backgroundColor: "#f0f0f0",
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      overflow: "hidden",
    },
    INFO: {
      height: 64,
      width: WIDTH,
      position: "relative",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      padding: "0px 20px 5px 20px",
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,

      "&::after": {
        content: `""`,
        position: "absolute",
        bottom: 0,
        left: 0,
        height: 8,
        width: "100%",
        backgroundColor: "rgba(0,0,0,.25)",
      },
    },
    DETAILS: {
      textTransform: "uppercase",
      flexGrow: 1,
      lineHeight: 1,
      color: "#333333",
      // paddingRight: 15,
      textAlign: (props) => (props.FLIPPED ? "right" : "left"),
      "& .org": {
        fontFamily: `"CINZEL bold"`,
        fontSize: 24,
        lineHeight: 0.9,
        letterSpacing: -1,
        color: "#004fff",
      },
    },
    SCORE: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      fontSize: 22,
      lineHeight: 1,
      color: "#004fff",
      fontFamily: `"CINZEL bold"`,
    },
    BANNER: {
      height: HEIGHT,
      width: WIDTH,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      position: "relative",

      "& .banner": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: `grayscale(40%) sepia(50%) opacity(0.8)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .player": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundSize: "contain",
        backgroundPosition: "bottom center",
        backgroundRepeat: "no-repeat",
      },

      "& .outline": {
        height: HEIGHT - 20,
        width: WIDTH - 20,
        backgroundSize: "100% 100%",
        backgroundImage: `url("${outline}")`,
        position: "absolute",
        zIndex: 20,
      },
    },
    BUG: {
      color: "#fff",
      opacity: 0.75,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: 12,
      letterSpacing: 1,
      position: "absolute",
      top: 20,
      right: 16,
      zIndex: 20,
    },
    AGENT: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      backgroundImage: (props) => `url("${props.AGENT}")`,
      position: "absolute",
      bottom: 0,
      right: 0,
      height: 140,
      width: 80,
      zIndex: 999,
    },
  }
);

const PlayerCard = ({
  TEAM,
  MATCH,
  DELAY,
  FLIPPED = false,
  PLAYER_IMAGE,
  IMAGE_SETTINGS,
  PLAYER_NAME,
  AGENT,
  CUSTOM_BG,
  CUSTOM_BG_SETTINGS,
}: {
  TEAM?: Participant;
  MATCH?: Match;
  DELAY?: number;
  FLIPPED?: boolean;
  PLAYER_NAME?: string;
  PLAYER_IMAGE?: string;
  IMAGE_SETTINGS?: Partial<AdjustmentsProps>;
  AGENT?: Agent;
  CUSTOM_BG?: string;
  CUSTOM_BG_SETTINGS?: Partial<AdjustmentsProps>;
}) => {
  const CLASSES = mcs({
    posX: Math.floor(Math.random() * 80),
    FLIPPED,
    AGENT: AGENT ? agents[AGENT] : "",
  });
  const { getTeamGroupsResult, score } = useTournament();

  const ORG = TEAM?.org_shortname;
  const SCHOOL_SHORT = TEAM?.university_acronym;
  const IS_GROUPS = !!MATCH?.group_id;
  const SCORE = getScore();

  function getScore() {
    if (!MATCH) return "";
    if (IS_GROUPS) {
      const { wins, loses } = getTeamGroupsResult(TEAM?.id);
      return `${wins}-${loses}`;
    }
    const { wins } = score(MATCH, TEAM?.id);
    return wins;
  }

  // <------------ ANIMATION STUFF ----------->
  const styles = getStyles(!!PLAYER_IMAGE, DELAY);
  const adjust = useAdjustments();

  return (
    <div className={CLASSES.BODY} style={styles.body}>
      {/* <div className={CLASSES.AGENT}></div> */}
      <div className={CLASSES.BANNER}>
        <div
          className="banner"
          style={{
            backgroundImage: `url("${
              TEAM?.university_banner_base64 || TEAM?.university_banner
            }")`,
            ...styles.bg,
          }}
        >
          <img
            src={CUSTOM_BG}
            height={HEIGHT}
            style={{
              position: "absolute",
              top: 0,
              width: "auto",
              zIndex: 10,
              ...adjust(CUSTOM_BG_SETTINGS),
            }}
          />
          <div className={CLASSES.BUG}>{ORG}</div>
        </div>
        <div style={{ height: "100%", width: "100%", ...styles.photo }}>
          <div
            className="player"
            style={{
              backgroundImage: `url("${PLAYER_IMAGE}")`,
              transformOrigin: "bottom center",
              ...adjust(IMAGE_SETTINGS),
            }}
          ></div>
        </div>
        <div className="outline"></div>
      </div>
      <div className={CLASSES.INFO}>
        <div className={CLASSES.DETAILS}>
          <div className="school">{SCHOOL_SHORT}</div>
          <div className="org">{PLAYER_NAME}</div>
        </div>
        <div className={CLASSES.SCORE}>{SCORE}</div>
      </div>
    </div>
  );
};

function getStyles(
  toggle: boolean = false,
  delay?: number
): { [key: string]: CSSProperties } {
  const bg = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      transitionProperty: "transform",
      transform: `scale(${isOn ? 1 : 1.4}) `,
    }),
  });

  const photo = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      transitionProperty: "transform",
      transformOrigin: "bottom center",
      transform: `scale(${isOn ? 1 : 0.93})`,
    }),
  });

  const body = TransitionProps(toggle, {
    duration: 600,
    introDelay: delay,
    props: (isOn) => ({
      opacity: isOn ? 1 : 0,
      transform: `translateY(${isOn ? 0 : -20}px)`,
    }),
  });

  const coin = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      opacity: isOn ? 1 : 0,
      transform: `
        scale(${isOn ? 1 : 1.2})
        rotate(${isOn ? 0 : 360}deg)
      `,
    }),
  });

  return { bg, body, coin, photo };
}
export default PlayerCard;
