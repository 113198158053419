import Coin from "@comps/containers/Coin";
import useTournament from "@lib/useTournament";
import { makeStyles } from "@material-ui/core";
import { format, isValid, parseISO } from "date-fns";
import React, { HTMLAttributes } from "react";
import middleLine from "@img/middle_line.png";
import divider from "@img/divider_horizontal_small.png";
import { Live, ReduxState } from "@defs/types";
import { useSelector } from "react-redux";
import { ScheduleMatch } from "../ScheduleMatch";

const mcs = makeStyles({
  BODY: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    padding: "25px 10px",
  },

  LABEL: {
    fontSize: "1em",
    textAlign: "center",
    color: "#fff",
    opacity: 0.5,
    flexShrink: 0,
    letterSpacing: 2,
  },
  DIVIDER: {
    margin: 20,
    height: 10,
    width: 220,
    flexShrink: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("${divider}")`,
  },
});

const Schedule = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const CLASSES = mcs();
  return (
    <div className={`${className} ${CLASSES.BODY}`} {...props}>
      <div className={CLASSES.LABEL}>UP NEXT</div>
      <CurrentMatch />
      <div className={CLASSES.DIVIDER} />
      <div className={CLASSES.LABEL}>UPCOMING GAMES</div>
      <Matches />
    </div>
  );
};

// <--------------- SCHEDULE --------------->

const matchesStyle = makeStyles<any, { nameSize?: number }>({
  BODY: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "10px 37px 0px 37px",
    position: "relative",
    flexGrow: 1,
  },
});

const Matches = () => {
  const CLASSES = matchesStyle({});
  const { matches_today: matches = [], match } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { sortMatches } = useTournament();
  const sorted = sortMatches(matches);

  const upcoming = sorted
    .filter((m, i) => m.id !== match?.id ?? 0)
    .filter((_, i) => i < 3);

  return (
    <div className={CLASSES.BODY}>
      {upcoming.map((match, key) => (
        <ScheduleMatch {...{ match, key }} showWinner />
      ))}
    </div>
  );
};

// <--------------- MATCH --------------->

const matchStyles = makeStyles({
  BODY: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
    position: "relative",
    flexShrink: 0,
  },
  MIDDLE: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    "& .name": {
      fontFamily: `'CINZEL bold'`,
      letterSpacing: 1,
      fontSize: 25,
      textAlign: "left",
      padding: "0px 2px",
      textTransform: "uppercase",
      width: "100%",
      color: "#fff",
    },

    "& .schedule": {
      margin: "5px 0px",
      height: 7,
      width: "150%",
      fontSize: 12,
      textAlign: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url("${middleLine}")`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#e7c314",
      textTransform: "uppercase",
    },
  },
});

const CurrentMatch = () => {
  const CLASSES = matchStyles();
  const { match, team } = useTournament();
  const style = { height: 105, width: 105 };

  const p1 = team(match?.player1_id);
  const p2 = team(match?.player2_id);

  const bestOf = match?.bestOf ?? 1;
  const schedule = format(
    new Date(
      !!match?.schedule && isValid(parseISO(match?.schedule + ""))
        ? match?.schedule
        : Date.now()
    ),
    "p"
  );

  return (
    <div className={CLASSES.BODY}>
      <Coin
        url={p1?.logo ?? ""}
        base64={p1?.logo_base64}
        style={{ ...style, zIndex: 50 }}
        className={p1?.badge_color}
      />
      <div className={CLASSES.MIDDLE}>
        <div className="name">{p1?.university_acronym}</div>
        <div className="schedule">{schedule}</div>
        <div className="name" style={{ textAlign: "right" }}>
          {p2?.university_acronym}
        </div>
      </div>
      <Coin
        url={p2?.logo ?? ""}
        base64={p2?.logo_base64}
        style={{ ...style, zIndex: 50 }}
        className={p2?.badge_color}
      />
    </div>
  );
};

export default Schedule;
