import Coin from "@comps/containers/Coin";
import useTournament from "@lib/useTournament";
import { Match } from "@defs/types";
import middleLine from "@img/middle_line.png";
import { makeStyles } from "@material-ui/core";
import { isValid, format, parseISO } from "date-fns";
import React from "react";

interface Props {
  match: Match;
  iconSize?: number;
  nameSize?: number;
  scheduleSize?: number;
  dividerHeight?: number;
  middleMarginY?: number;
  showWinner?: boolean;
  nameMarginX?: boolean;
}

const matchesStyle = makeStyles<any, Partial<Props>>({
  MIDDLE: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    "& .name": {
      fontFamily: `'CINZEL bold'`,
      letterSpacing: 1,
      fontSize: (props) => props.nameSize ?? 20,
      padding: "0px 2px",
      textTransform: "uppercase",
      width: "100%",
      color: "#fff",
      display: "flex",
      "& .win": {
        color: "#e7c314",
        fontFamily: `FUTURA`,
        fontSize: (props) => (props.nameSize ?? 20) - 12,
      },
    },

    "& .p1": {
      flexDirection: "column-reverse",
    },
    "& .p2": {
      flexDirection: "column",
      textAlign: "right",
    },

    "& .schedule": {
      margin: (props) => `${props.middleMarginY ?? 5}px 0px`,
      height: (props) => props.dividerHeight ?? 7,
      width: "150%",
      fontSize: (props) => props.scheduleSize ?? 12,
      textAlign: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url("${middleLine}")`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#e7c314",
      textTransform: "uppercase",
    },
  },
  MATCH: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});
export const ScheduleMatch = ({
  match,
  iconSize = 80,
  showWinner,
  ...props
}: Props) => {
  const CLASSES = matchesStyle(props);
  const { team } = useTournament();

  const style = { height: iconSize, width: iconSize, zIndex: 50 };

  const P1_ID = match.player1_id;
  const P2_ID = match.player2_id;
  const P1 = team(match.player1_id);
  const P2 = team(match.player2_id);
  const WINNER = match.winner_id;

  const schedule = format(
    new Date(
      !!match?.schedule && isValid(parseISO(match?.schedule + ""))
        ? match?.schedule
        : Date.now()
    ),
    "p"
  );
  const isFinished = !!match.winner_id;

  return (
    <div className={CLASSES.MATCH}>
      <Coin
        url={P1?.logo}
        base64={P1?.logo_base64}
        style={{
          ...style,
          filter: `brightness(${
            isFinished ? (WINNER === P1_ID ? 100 : 30) : 100
          }%)`,
        }}
        color={P1?.badge_color}
        shadow={!WINNER || WINNER === P1_ID}
      />
      <div className={CLASSES.MIDDLE}>
        <div className="name p1">
          <div>{P1?.university_acronym}</div>
          {showWinner && WINNER === P1_ID && <div className="win">WIN</div>}
        </div>
        <div className="schedule">{isFinished ? "DONE" : schedule}</div>
        <div className="name p2">
          <div>{P2?.university_acronym}</div>
          {showWinner && WINNER === P2_ID && <div className="win">WIN</div>}
        </div>
      </div>
      <Coin
        url={P2?.logo}
        base64={P2?.logo_base64}
        style={{
          ...style,
          filter: `brightness(${
            isFinished ? (WINNER === P2_ID ? 100 : 30) : 100
          }%)`,
        }}
        color={P2?.badge_color}
        shadow={!WINNER || WINNER === P2_ID}
      />
    </div>
  );
};

export default ScheduleMatch;
