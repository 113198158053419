import LowerThirds from "@comps/containers/LowerThirds";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  BODY: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  STAT: {
    textTransform: "uppercase",
    display: "flex",
    padding: "5px 40px 5px 0px",
    borderRight: "3px solid rgba(0,0,0,.5)",
    alignItems: "flex-end",
    fontFamily: `'CINZEL bold'`,

    "&:last-of-type": {
      paddingRight: 0,
      borderRight: "none",
    },
    "& .name": {
      fontSize: 30,
      fontFamily: `'CINZEL bold'`,
      lineHeight: 1,
      marginRight: 20,
      paddingBottom: 8,
    },
    "& .value": {
      fontSize: 60,
      lineHeight: 1,
      color: "#2448bc",
    },
  },
});

const Lodi2 = () => {
  const CLASSES = mcs();
  const { logitech_mvp } = useSelector<ReduxState, Live>((state) => state.live);
  return (
    <LowerThirds clear>
      <div className={CLASSES.BODY}>
        <div className={CLASSES.STAT}>
          <div className="name">KILLS</div>
          <div className="value">{logitech_mvp?.stats[0]?.stat_value}</div>
        </div>
        <div className={CLASSES.STAT}>
          <div className="name">DEATHS</div>
          <div className="value">{logitech_mvp?.stats[1]?.stat_value}</div>
        </div>
        <div className={CLASSES.STAT}>
          <div className="name">ASSISTS</div>
          <div className="value">{logitech_mvp?.stats[2]?.stat_value}</div>
        </div>
      </div>
    </LowerThirds>
  );
};

export default Lodi2;
