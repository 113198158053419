import TeamNameTag from "@comps/containers/TeamNameTag";
import { Live, ReduxState } from "@defs/types";
import useTournament from "@lib/useTournament";
import React from "react";
import { useSelector } from "react-redux";

const TeamWidget = ({
  teamNum,
  flipped,
}: {
  teamNum: 1 | 2;
  flipped?: boolean;
}) => {
  const { swap_team_positions: swap } = useSelector<ReduxState, Live>(
    (s) => s.live
  );
  const { team, match } = useTournament();

  const P1 = match?.player1_id;
  const P2 = match?.player2_id;
  const ID = swap ? (teamNum === 1 ? P2 : P1) : teamNum === 1 ? P1 : P2;
  const TEAM = team(ID);
  return (
    <div style={{ padding: 100 }}>
      <TeamNameTag team={TEAM} match={match} flipped={flipped} />
    </div>
  );
};

export default TeamWidget;
