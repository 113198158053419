import React, { useState, useEffect, FC } from "react";

export const WindowContext = React.createContext<boolean>(false);

export const WindowContextProvider: FC = ({ children }) => {
  const [windowIsActive, set] = useState(true);

  function handleActivity() {
    return document.hidden ? set(false) : set(true);
  }

  useEffect(() => {
    const setFalse = () => set(false);
    const setTrue = () => set(true);

    document.addEventListener("visibilitychange", handleActivity);
    document.addEventListener("blur", setFalse);
    window.addEventListener("blur", setFalse);
    window.addEventListener("focus", setTrue);
    document.addEventListener("focus", setTrue);

    return () => {
      window.removeEventListener("blur", handleActivity);
      document.removeEventListener("blur", setFalse);
      window.removeEventListener("focus", setFalse);
      document.removeEventListener("focus", setTrue);
      document.removeEventListener("visibilitychange", setTrue);
    };
  }, []);

  return (
    <WindowContext.Provider value={windowIsActive}>
      {children}
    </WindowContext.Provider>
  );
};
