import Coin from "@comps/containers/Coin";
import useTournament from "@lib/useTournament";
import { Match, Participant } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React, { CSSProperties, FC, HTMLAttributes } from "react";
import divider from "@img/divider_horizontal_small.png";
import { format, isValid, parseISO } from "date-fns";

const mcs = makeStyles({
  BODY: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-evenly",
    "& .label": {
      position: "absolute",
      top: 0,
      right: 0,
      color: "#fff",
      opacity: 0.8,
      fontSize: "1.2em",
      padding: "12px 10px",
    },
  },
  TEAM: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 15px",
  },
  DETAILS: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0px 5px",
    color: "#fff",
    "& .shortcode": {
      fontFamily: `'CINZEL bold'`,
      fontSize: 40,
      letterSpacing: 1,
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
      textAlign: "center",
      textTransform: "uppercase",
    },
    "& .name": {
      fontFamily: `FUTURA`,
      letterSpacing: 1,
      fontSize: 12,
      textAlign: "center",
      textTransform: "uppercase",
    },
  },
  DIVIDER: {
    marginTop: 10,
    height: 10,
    width: 135,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url("${divider}")`,
  },
  SCORE: {
    fontFamily: `'CINZEL bold'`,
    marginTop: 8,
    color: "#e7c314",
    fontSize: 25,
    letterSpacing: 1,
  },
  VS: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    "& .vs": {
      fontFamily: "THEALIENS",
      flexShrink: 0,
      fontSize: 55,
      letterSpacing: 5,
    },
    "& .text": {
      flexGrow: 1,
      textTransform: "uppercase",
      textAlign: "center",
      letterSpacing: 2,
      fontSize: 15,
      opacity: 0.8,
      paddingTop: 8,
    },
  },
});

const MatchContent = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const CLASSES = mcs();

  const { match, team } = useTournament();
  const p1 = team(match?.player1_id);
  const p2 = team(match?.player2_id);

  const bestOf = match?.bestOf ?? 1;
  const schedule = format(
    new Date(
      !!match?.schedule && isValid(parseISO(match?.schedule + ""))
        ? match?.schedule
        : Date.now()
    ),
    "p"
  );

  return (
    <div className={`${className} ${CLASSES.BODY}`} {...props}>
      <div className="label">UP NEXT</div>
      <Team team={p1} match={match} reverse />

      <div className={CLASSES.VS}>
        <div className="text">BEST OF {bestOf}</div>
        <div className="vs">VS</div>
        <div className="text">{schedule}</div>
      </div>

      <Team team={p2} match={match} />
    </div>
  );
};

const size = 150;
const style: CSSProperties = { height: size, width: size };

interface TeamProps {
  team?: Participant;
  reverse?: boolean;
  match?: Match;
}

const Team = ({ team, reverse, match }: TeamProps) => {
  const CLASSES = mcs();

  const { getTeamGroupsResult, score: matchScore } = useTournament();

  const url = team?.logo ?? "";
  const base64 = team?.logo_base64 ?? "";
  const shortcode = team?.org_acronym ?? "";
  const name = team?.org_name ?? "";
  const isGroups = !!match?.group_id;
  const score = getTeamGroupsResult(team?.id);
  const color = team?.badge_color;
  const matchScoreResult = matchScore(match, team?.id);

  return (
    <div
      className={CLASSES.TEAM}
      style={{ flexDirection: reverse ? "row-reverse" : "row" }}
    >
      <Coin {...{ url, base64, style, color }} />
      <div className={CLASSES.DETAILS}>
        <div className="shortcode">{shortcode}</div>
        <div className="name">{name}</div>
        <div className={CLASSES.DIVIDER}></div>
        {isGroups ? (
          <div className={CLASSES.SCORE}>
            {score.wins} - {score.loses}
          </div>
        ) : (
          <div className={CLASSES.SCORE}>{matchScoreResult.wins}</div>
        )}
      </div>
    </div>
  );
};

export default MatchContent;
