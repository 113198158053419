import { Live, ReduxState } from "@defs/types";
import useAdjustments from "@lib/useAdjustments";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const mcs = makeStyles({
  BODY: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "blue",
    padding: "0px 50px",
    position: "relative",
    "& .transitions-enter": {
      opacity: 0,
    },
    "& .transitions-enter-active": {
      opacity: 1,
    },
    "& .transitions-exit": {
      opacity: 1,
    },
    "& .transitions-exit-active": {
      opacity: 0,
    },
    "& .transitions-enter-active, .transitions-exit-active": {
      transition: "all 500ms",
    },
  },
  IMG: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
  },
  TEXT: {
    paddingLeft: 40,
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    flexGrow: 1,
    "& .head": {
      color: "#2259e2",
      fontFamily: `"CINZEL bold"`,
      fontSize: 26,
      textTransform: "uppercase",
    },
    "& .content": {
      textTransform: "uppercase",
      fontSize: 12,
      textAlign: "left",
      color: "#333333",
    },
  },
});
const Ad = () => {
  const CLASSES = mcs();
  const { lowerThirdsIngame } = useSelector<ReduxState, Live>((s) => s.live);
  const adjust = useAdjustments();

  const AD = lowerThirdsIngame?.ad;
  const HEAD = AD?.head;
  const CONTENT = AD?.content;
  const IMG = AD?.img;

  return (
    <SwitchTransition>
      <CSSTransition
        key={AD?.content.text ?? "none"}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="transitions"
      >
        <div className={CLASSES.BODY}>
          <div
            className={CLASSES.IMG}
            style={{
              backgroundImage: `url("${IMG?.url_base64 || IMG?.url}")`,
              ...adjust(IMG?.adj),
            }}
          ></div>
          <div className={CLASSES.TEXT}>
            <div className="head" style={adjust(HEAD?.adj)}>
              {HEAD?.text}
            </div>
            <div className="content" style={adjust(CONTENT?.adj)}>
              {CONTENT?.text}
            </div>
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Ad;
