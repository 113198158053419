import React from "react";
import "./App.css";
import { makeStyles } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import CasterCam from "@view/CasterCam";
import Super from "@view/Super/Super";
import Caster from "@view/Caster";
import LowerThirdsPreview from "@view/LowerThirds.preview";
import Coin from "@comps/containers/Coin";
import Match from "@comps/containers/CardVertical";
import CardBig from "@comps/containers/CardBig";
import Ingame from "@view/Ingame";
import TeamWidget from "@view/TeamWidget";
import VsScreen from "@view/VsScreen";
import Veto from "@view/Veto";
import Winner from "@view/Winner";
import Lodi from "@view/Lodi";
import Lodi2 from "@view/Lodi2";
import IngameWR from "@view/IngameWR";

const ms = makeStyles({
  app: {
    overflow: "hidden",
    width: 1920,
    height: 1080,
    backgroundColor: "transparent",
  },
});

const routes = [
  { path: "/winner", component: <Winner />, exact: true },
  { path: "/veto", component: <Veto />, exact: true },
  { path: "/vs", component: <VsScreen />, exact: true },
  { path: "/castercam", component: <CasterCam />, exact: true },
  { path: "/super", component: <Super />, exact: true },
  { path: "/match", component: <Match />, exact: true },
  { path: "/lt", component: <LowerThirdsPreview />, exact: true },
  { path: "/caster/:casterIndex", component: <Caster />, exact: true },
  { path: "/module", component: <CardBig />, exact: true },
  { path: "/ingame", component: <Ingame />, exact: true },
  { path: "/ingame/wr", component: <IngameWR />, exact: true },
  { path: "/lodi", component: <Lodi />, exact: true },
  { path: "/lodi2", component: <Lodi2 />, exact: true },
  { path: "/team1", component: <TeamWidget teamNum={1} />, exact: true },
  {
    path: "/team2",
    component: <TeamWidget teamNum={2} flipped />,
    exact: true,
  },
];

function App() {
  const c = ms();
  return (
    <div className={c.app}>
      <Switch>
        {routes.map(({ component, ...route }) => (
          <Route key={route.path} {...route}>
            {component}
          </Route>
        ))}
      </Switch>
    </div>
  );
}

export default App;
