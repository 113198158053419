import { makeStyles } from "@material-ui/core";
import React from "react";
import bg from "@img/top.png";
import useTournament from "@lib/useTournament";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";
import Coin from "@comps/containers/Coin";

const mcs = makeStyles({
  BODY: {
    height: 74,
    width: 1920,
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "100% 100%",
    backgroundImage: `url(${bg})`,
  },
  MIDDLE: {
    padding: 7,
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 16,
  },
  TEAM: {
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 4px 10px",
    width: 400,
    "& .text": {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      padding: "0px 10px",
      textTransform: "uppercase",
      "& .school": {
        color: "#fff",
        fontSize: 12,
      },
      "& .org": {
        color: "#e7c314",
        fontFamily: `"CINZEL bold"`,
        fontSize: 24,
      },
    },
    "& .score": {
      color: "#fff",
      fontFamily: `"CINZEL bold"`,
      fontSize: 24,
      padding: "0px 15px",
      whiteSpace: "nowrap",
    },
  },
  COIN: {
    height: 65,
    width: 65,
    filter: "drop-shadow(0px 8px 8px rgba(0,0,0,.5))",
  },
});

const Ingame = () => {
  const CLASSES = mcs();
  const { swap_team_positions: swap, live_data } = useSelector<
    ReduxState,
    Live
  >((s) => s.live);

  const SPLIT = live_data?.split_title ?? "";
  const STAGE = live_data?.stage ?? "";

  const { match, team, getTeamGroupsResult, score } = useTournament();
  const P1_ID = swap ? match?.player2_id : match?.player1_id;
  const P2_ID = swap ? match?.player1_id : match?.player2_id;
  const P1 = team(P1_ID);
  const P2 = team(P2_ID);

  function getScore(id: number = 0) {
    const IS_GROUPS = !!match?.group_id;
    if (IS_GROUPS) {
      const SCORE = getTeamGroupsResult(id);
      return `${SCORE.wins}-${SCORE.loses}`;
    }
    return score(match, id).wins;
  }

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.TEAM}>
        <Coin
          base64={P1?.logo_base64}
          url={P1?.logo}
          className={CLASSES.COIN}
          color={P1?.badge_color}
        />
        <div className="text">
          <div className="school">{P1?.university_name}</div>
          <div className="org">{P1?.org_shortname || P1?.org_name}</div>
        </div>
        <div className="score">{getScore(P1?.id)}</div>
      </div>

      <div className={CLASSES.MIDDLE}>
        <span style={{ color: "#e7c314" }}>{SPLIT}</span>
        <span>{STAGE}</span>
      </div>

      <div className={CLASSES.TEAM} style={{ flexDirection: "row-reverse" }}>
        <Coin
          base64={P2?.logo_base64}
          url={P2?.logo}
          className={CLASSES.COIN}
          color={P2?.badge_color}
        />
        <div
          className="text"
          style={{ alignItems: "flex-end", textAlign: "right" }}
        >
          <div className="school">{P2?.university_name}</div>
          <div className="org">{P2?.org_shortname || P2?.org_name}</div>
        </div>
        <div className="score">{getScore(P2?.id)}</div>
      </div>
    </div>
  );
};

export default Ingame;
