import { makeStyles } from "@material-ui/core";
import React from "react";
import bg from "@img/ingame-wr.png";
import useTournament from "@lib/useTournament";
import { useSelector } from "react-redux";
import { Live, ReduxState } from "@defs/types";

const mcs = makeStyles({
  BODY: {
    height: 1080,
    width: 1920,
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "100% 100%",
    backgroundImage: `url(${bg})`,
    position: "relative",
  },

  TEAMS: {
    display: "flex",
    position: "absolute",
    top: 0,
    width: "100%",

    "& .team1": {
      "& .logo": { left: 377 },
      "& .details": { left: 598, color: "#fff" },
      "& .score": { left: 847 },
    },
    "& .team2": {
      "& .logo": { left: 1461 },
      "& .details": { left: 1090 },
      "& .score": { left: 1017 },
    },
  },
  TEAM: {
    position: "absolute",
    "& .logo": {
      position: "absolute",
      top: 5,
      width: 109,
      height: 69,
      backgroundPosition: "center",
      backgroundSize: "auto 85%",
      backgroundRepeat: "no-repeat",
    },
    "& .details": {
      position: "absolute",
      width: 231,
      padding: "0 15px",
      height: 57,
      top: 69,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .school": {
        fontFamily: `"CINZEL bold"`,
        fontSize: 40,
        paddingRight: 10,
        flexShrink: 0,
        lineHeight: 1,
        whiteSpace: "pre",
        textAlign: "center",
      },
      "& .org": {
        fontSize: 20,
        lineHeight: 0.85,
        textTransform: "uppercase",
      },
    },
    "& .score": {
      position: "absolute",
      height: 57,
      top: 69,
      fontFamily: `"FUTURA bold"`,
      fontSize: 30,
      width: 52,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      whiteSpace: "nowrap",
      wordBreak: "keep-all",
    },
  },

  STAGE: {
    position: "absolute",
    width: 257,
    height: 34,
    top: 190,
    left: 1640,
    color: "#fff",
    fontFamily: `CINZEL`,
    fontSize: 22,
    textTransform: "uppercase",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
});

const IngameWR = () => {
  const CLASSES = mcs();
  const { swap_team_positions: swap, live_data } = useSelector<
    ReduxState,
    Live
  >((s) => s.live);

  const { match, team, getTeamGroupsResult, score } = useTournament();
  const P1_ID = swap ? match?.player2_id : match?.player1_id;
  const P2_ID = swap ? match?.player1_id : match?.player2_id;
  const P1 = team(P1_ID);
  const P2 = team(P2_ID);

  function getScore(id: number = 0) {
    const IS_GROUPS = !!match?.group_id;
    if (IS_GROUPS) {
      const SCORE = getTeamGroupsResult(id);
      return `${SCORE.wins}-${SCORE.loses}`;
    }
    return score(match, id).wins;
  }

  const P1_SCHOOL_LENGTH = (P1?.university_acronym ?? "").length;
  const P2_SCHOOL_LENGTH = (P2?.university_acronym ?? "").length;

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.TEAMS}>
        <div className={CLASSES.TEAM + " team1"}>
          <div
            className="logo"
            style={{
              backgroundImage: `url("${P1?.logo_base64 || P1?.logo}")`,
            }}
          ></div>
          <div className="details">
            <div
              className="school"
              style={{
                fontSize:
                  P1_SCHOOL_LENGTH > 5 ? 25 : P1_SCHOOL_LENGTH > 3 ? 30 : 40,
              }}
            >
              {(P1?.university_acronym ?? "").replace("-", "\n")}
            </div>
            <div className="org">{P1?.org_name}</div>
          </div>
          <div className="score">{getScore(P1?.id)}</div>
        </div>
        <div className={CLASSES.TEAM + " team2"}>
          <div
            className="logo"
            style={{
              backgroundImage: `url("${P2?.logo_base64 || P2?.logo}")`,
            }}
          ></div>
          <div className="details">
            <div
              className="school"
              style={{
                fontSize:
                  P2_SCHOOL_LENGTH > 5 ? 25 : P2_SCHOOL_LENGTH > 3 ? 30 : 40,
              }}
            >
              {(P2?.university_acronym ?? "").replace("-", "\n")}
            </div>
            <div className="org">{P2?.org_name}</div>
          </div>
          <div className="score">{getScore(P2?.id)}</div>
        </div>
      </div>
      <div className={CLASSES.STAGE}>{live_data?.ingame ?? ""}</div>
    </div>
  );
};

export default IngameWR;
