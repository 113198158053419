import TeamCard from "@comps/containers/TeamCard";
import { Live, ReduxState } from "@defs/types";
import useTournament from "@lib/useTournament";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const mcs = makeStyles({
  BODY: {
    position: "relative",
    height: 1080,
    width: 1920,
  },
  WRAP: {
    position: "absolute",
    top: 216,
  },
});
const VsScreen = () => {
  const CLASSES = mcs();
  const { swap_team_positions: swap, vs_screen } = useSelector<
    ReduxState,
    Live
  >((s) => s.live);
  const { match, team } = useTournament();

  const P1_ID = swap ? match?.player2_id : match?.player1_id;
  const P2_ID = swap ? match?.player1_id : match?.player2_id;
  const P1_PHOTO = swap
    ? vs_screen?.team1_player?.photo_main
    : vs_screen?.team2_player?.photo_main;
  const P2_PHOTO = swap
    ? vs_screen?.team2_player?.photo_main
    : vs_screen?.team1_player?.photo_main;
  const P1_ADJUSTMENTS = swap
    ? vs_screen?.team2_player_settings
    : vs_screen?.team1_player_settings;
  const P2_ADJUSTMENTS = swap
    ? vs_screen?.team1_player_settings
    : vs_screen?.team2_player_settings;
  const P1 = team(P1_ID);
  const P2 = team(P2_ID);

  // <---------------------------------------

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.WRAP} style={{ left: 188 }}>
        <TeamCard
          TEAM={P1}
          MATCH={match}
          PLAYER_IMAGE={P2_PHOTO}
          IMAGE_SETTINGS={P1_ADJUSTMENTS}
        />
      </div>
      <div className={CLASSES.WRAP} style={{ right: 183 }}>
        <TeamCard
          TEAM={P2}
          MATCH={match}
          DELAY={200}
          PLAYER_IMAGE={P1_PHOTO}
          IMAGE_SETTINGS={P2_ADJUSTMENTS}
          FLIPPED
        />
      </div>
    </div>
  );
};

export default VsScreen;
