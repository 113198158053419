import PlayerCard from "@comps/containers/PlayerCard";
import { Live, PlayerStatProps, ReduxState, Stat } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import divider from "@img/divider_horizontal_small.png";
import { TransitionProps } from "@lib/CSStransition";
import { Opacity } from "@material-ui/icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const mcs = makeStyles({
  BODY: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  CONTENT: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  HEAD: {
    color: "#fff",
    opacity: 0.6,
    padding: "20px 0px 10px 0px",
    letterSpacing: 1,
  },
  STATS: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    padding: "0px 10px",
    color: "#fff",
    "& .intro-enter": {
      opacity: 0,
      transform: "translateY(10px)",
    },
    "& .intro-enter-done": {
      opacity: 1,
      transform: "translateY(0px)",
      transition: "all 600ms cubic-bezier(0.65, 0, 0.35, 1)",
    },
    ".intro-exit": {
      transform: "translateY(0px)",
      opacity: 1,
    },
    "& .intro-exit-done": {
      transform: "translateY(10px)",
      opacity: 0,
      transition: "all 600ms cubic-bezier(0.65, 0, 0.35, 1)",
    },
  },
  STAT: {
    width: "100%",
    margin: "15px 0px",
    "& .label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& .name": {
        fontSize: 24,
        fontFamily: `'CINZEL bold'`,
        textTransform: "uppercase",
      },
      "& .separator": {
        margin: 10,
        height: 10,
        width: "60%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url("${divider}")`,
        opacity: 0.8,
      },
    },
    "& .values": {
      fontSize: 34,
      fontFamily: `'CINZEL bold'`,
      textTransform: "uppercase",
      padding: "0px 20px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const PlayerVsPlayer = () => {
  const CLASSES = mcs();
  const { stat_player_vs } = useSelector<ReduxState, Live>(
    (state) => state.live
  );

  const player1 = stat_player_vs?.player1;
  const player1Settings = stat_player_vs?.player1_settings;
  const player1Stats = stat_player_vs?.player1.stats ?? [];
  const player2 = stat_player_vs?.player2;
  const player2Settings = stat_player_vs?.player2_settings;
  const player2Stats = stat_player_vs?.player2.stats ?? [];
  const statNames = stat_player_vs?.stat_names ?? [];

  const getStatValue = (stats: Stat[], name: string) => {
    const stat = stats.find((s) => s.stat_name === name);
    if (stat) {
      return stat.stat_value;
    }
    return "";
  };

  const moreThan = (
    value1: string,
    value2: string,
    inverse: boolean = false
  ) => {
    const v1 = parseInt(value1);
    const v2 = parseInt(value2);
    if (v1 > v2) {
      return inverse ? false : true;
    }
    return inverse ? true : false;
  };

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.HEAD}>MATCHUP</div>
      <div className={CLASSES.CONTENT}>
        <PlayerCard
          PLAYER_IMAGE={player1?.photo_main}
          TEAM={player1?.team}
          PLAYER_NAME={player1?.ign}
          IMAGE_SETTINGS={player1Settings}
          DELAY={250}
        />
        <TransitionGroup className={CLASSES.STATS}>
          {statNames.map((stat, i) => (
            <CSSTransition key={i} timeout={2400} classNames="intro">
              <div
                key={i}
                className={CLASSES.STAT}
                style={{ transitionDelay: `${600 * i}ms` }}
              >
                <div className="values">
                  <div
                    className="value"
                    style={{
                      color: moreThan(
                        getStatValue(player1Stats, stat),
                        getStatValue(player2Stats, stat),
                        stat.toLowerCase() === "deaths"
                      )
                        ? "#e7c70c"
                        : "#fff",
                    }}
                  >
                    {getStatValue(player1Stats, stat)}
                  </div>
                  <div
                    className="value"
                    style={{
                      color: moreThan(
                        getStatValue(player2Stats, stat),
                        getStatValue(player1Stats, stat),
                        stat.toLowerCase() === "deaths"
                      )
                        ? "#e7c70c"
                        : "#fff",
                    }}
                  >
                    {getStatValue(player2Stats, stat)}
                  </div>
                </div>
                <div className="label">
                  <div className="name">{stat}</div>
                  <div className="separator"></div>
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {/* <div className={CLASSES.STATS}></div> */}
        <PlayerCard
          PLAYER_IMAGE={player2?.photo_main}
          TEAM={player2?.team}
          PLAYER_NAME={player2?.ign}
          IMAGE_SETTINGS={player2Settings}
          DELAY={500}
          FLIPPED
        />
      </div>
    </div>
  );
};

export default PlayerVsPlayer;
