import TalentNameTag from "@comps/containers/CasterNameTag";
import { Live, ReduxState } from "@defs/types";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

const Caster = () => {
  const params = useParams<{ casterIndex: string }>();
  const { search } = useLocation<{ flipX: string }>();
  const query = new URLSearchParams(search);
  const flipX = query.get("flipX");

  const i = parseInt(params.casterIndex) - 1;
  const { casters = [] } = useSelector<ReduxState, Live>((s) => s.live);

  const CASTER = casters[i];
  console.log(search);
  return (
    <TalentNameTag
      screenName={CASTER?.name || `GUEST`}
      socialLink={CASTER?.ign}
      flipX={!!flipX}
    />
  );
};

export default Caster;
