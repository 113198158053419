import { makeStyles } from "@material-ui/core";
import React from "react";
import bg from "@img/TeamNameTag.png";
import { Match, Participant } from "@defs/types";
import useTournament from "@lib/useTournament";
import Coin from "./Coin";

//c3c3c3
//ececec

const COIN_SIZE = 180;

const mcs = makeStyles<any, { flipped: boolean }>({
  body: {
    height: 130,
    width: 558,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: (props) => (props.flipped ? "row-reverse" : "row"),
    zIndex: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: ({ flipped }) => (flipped ? 0 : 110),
    paddingRight: ({ flipped }) => (flipped ? 110 : 0),
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      backgroundSize: "100% 100%",
      backgroundImage: `url("${bg}")`,
      transform: (props) => `scaleX(${props.flipped ? -1 : 1})`,
      zIndex: -1,
    },
  },
  logo: {
    width: 86,
    height: 80,
    display: "flex",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginLeft: (props) => (props.flipped ? 20 : 28),
    marginRight: (props) => (props.flipped ? 28 : 20),
    flexShrink: 0,
  },
  text: {
    display: "flex",
    flexDirection: "column",
    textAlign: (props) => (props.flipped ? "right" : "left"),
    overflowX: "visible",
    width: 297,

    "& .org": {
      paddingTop: 4,
      fontFamily: `'CINZEL bold'`,
      fontWeight: 900,
      fontSize: 34,
      textTransform: "uppercase",
      lineHeight: 1,
      color: "#fbd239",
      whiteSpace: "nowrap",
    },
    "& .school": {
      fontSize: 16,
      textTransform: "uppercase",
      lineHeight: 1,
      whiteSpace: "nowrap",
      color: "#fff",
    },
  },
  score: {
    fontFamily: `"FUTURA bold"`,
    fontWeight: 900,
    fontSize: 45,
    textTransform: "uppercase",
    lineHeight: 1,
    color: "#fff",
    textAlign: "center",
    margin: "0px 40px",
    width: 71,
    flexShrink: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    height: 18,
    width: 558,

    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    display: "flex",
    flexDirection: "column-reverse",
    "& > div": {
      height: 6,
      backgroundColor: "rgba(0,0,0,.2)",
    },
  },
  COIN: {
    height: COIN_SIZE,
    width: COIN_SIZE,
    position: "absolute",
    left: (props) =>
      props.flipped ? `calc(100% - ${COIN_SIZE / 2}px)` : "auto",
    right: (props) =>
      props.flipped ? "auto" : `calc(100% - ${COIN_SIZE / 2}px)`,
  },
});

const TeamNameTag = ({
  team,
  match,
  flipped = false,
}: {
  team?: Participant;
  match?: Match;
  disableSchool?: boolean;
  flipped?: boolean;
}) => {
  const CLASSES = mcs({ flipped });
  const { score, getTeamGroupsResult } = useTournament();

  const TEAM_ID = team?.id ?? 0;
  const LOGO = team?.logo ?? "";
  const BASE64 = team?.logo_base64 ?? "";
  const ORG_NAME = team?.org_name ?? "";
  const SCHOOL_NAME = team?.university_name ?? "";
  const COLOR = team?.badge_color;

  const IS_GROUPS = !!match?.group_id;
  const GROUPS_SCORE = getTeamGroupsResult(TEAM_ID);
  const SCORE = IS_GROUPS
    ? `${GROUPS_SCORE.wins}-${GROUPS_SCORE.loses}`
    : score(match, TEAM_ID).wins;

  return (
    <div className={CLASSES.wrapper}>
      <div className={CLASSES.body}>
        <Coin
          base64={BASE64}
          url={LOGO}
          className={CLASSES.COIN}
          shadow
          color={COLOR}
        />
        <div className={CLASSES.text}>
          <div
            className="school"
            style={{ fontSize: SCHOOL_NAME.length > 37 ? 15 : 16 }}
          >
            {SCHOOL_NAME}
          </div>
          <div
            className="org"
            style={{ fontSize: ORG_NAME.length > 14 ? 28 : 34 }}
          >
            {ORG_NAME}
          </div>
        </div>
        <div className={CLASSES.score}>{SCORE}</div>
      </div>
      <div className={CLASSES.footer}>
        <div className=""></div>
      </div>
    </div>
  );
};

export default TeamNameTag;
