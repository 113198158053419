import { makeStyles } from "@material-ui/core";
import diamond from "@img/paper.jpg";
import { useSelector } from "react-redux";
import { Live, LowerThirdsMode, ReduxState } from "@defs/types";
import { TransitionProps } from "@lib/CSStransition";
import Ad from "@comps/contents/lower_thirds/Ad";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import uac from "@img/all_black_uac.png";
import { FC } from "react";

const mcs = makeStyles<any, Partial<Props> & { posY: number }>({
  BODY: {
    width: 834,
    height: 99,
    borderTopLeftRadius: (props) => (props.hasTransitions ? 10 : 0),
    borderTopRightRadius: (props) => (props.hasTransitions ? 10 : 0),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
    zIndex: 5,
  },

  WRAPPER: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderTopLeftRadius: (props) => (props.hasTransitions ? 10 : 0),
    borderTopRightRadius: (props) => (props.hasTransitions ? 10 : 0),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",

    // "& .bg": {
    // },

    "& .item2": {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      backgroundColor: "#ececec",
      left: 0,

      borderTopLeftRadius: (props) => (props.hasTransitions ? 10 : 0),
      borderTopRightRadius: (props) => (props.hasTransitions ? 10 : 0),
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },

    "& .texture": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderTopLeftRadius: (props) => (props.hasTransitions ? 10 : 0),
      borderTopRightRadius: (props) => (props.hasTransitions ? 10 : 0),
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      backgroundSize: "cover",
      backgroundImage: `url("${diamond}")`,
      transition: "all 1.2s cubic-bezier(0.65, 0, 0.35, 1)",
      backgroundPosition: (props) => `center ${props.posY}%`,
      opacity: 0.4,
      zIndex: 4,
      mixBlendMode: "hard-light",
    },

    "& .item": {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      willChange: "transform",
      transform: "translateY(101%)",
      zIndex: 1,
    },
  },
  CONTENT: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 999,

    "& .hook": {
      width: "100%",
      height: "100%",
    },
    "& .transitions-enter": {
      opacity: 0,
    },
    "& .transitions-enter-active": {
      opacity: 1,
    },
    "& .transitions-exit": {
      opacity: 1,
    },
    "& .transitions-exit-active": {
      opacity: 0,
    },
    "& .transitions-enter-active, .transitions-exit-active": {
      transition: "all 600ms",
    },
  },
  CLEARED: {
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: 999,
  },
});

interface Props {
  hasTransitions?: boolean;
  clear?: boolean;
}

const LowerThirds: FC<Props> = ({ hasTransitions, clear, children }) => {
  const CLASSES = mcs({ posY: Math.floor(Math.random() * 80), hasTransitions });

  const { lowerThirdsIngame } = useSelector<ReduxState, Live>((s) => s.live);
  const { ITEM_1_INTRO, ITEM_2_INTRO, BG, TEXTURE, CONTENT } = getStyles(
    lowerThirdsIngame?.is_live ?? false
  );

  const MODE = lowerThirdsIngame?.mode ?? LowerThirdsMode.ad;
  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.WRAPPER}>
        <div className="item" style={hasTransitions ? ITEM_1_INTRO : {}}></div>
        <div className="item" style={hasTransitions ? ITEM_2_INTRO : {}}></div>
        <div
          className="item2"
          style={hasTransitions ? BG : { opacity: 1 }}
        ></div>
        <div className="texture" style={hasTransitions ? TEXTURE : {}}></div>
      </div>
      {!clear && (
        <div className={CLASSES.CONTENT} style={hasTransitions ? CONTENT : {}}>
          <SwitchTransition>
            <CSSTransition
              key={MODE}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="transitions"
            >
              <div className="hook">
                {MODE === LowerThirdsMode.ad && <Ad />}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      )}
      {clear && <div className={CLASSES.CLEARED}>{children}</div>}
    </div>
  );
};

function getStyles(isOn: boolean) {
  /**
   * On content enter
   */
  const CONTENT = TransitionProps(isOn, {
    introDelay: 400,
    props: (on) => ({
      opacity: on ? 1 : 0,
      transform: `translateY(${on ? 0 : -10}px)`,
    }),
  });

  const BG = TransitionProps(isOn, {
    duration: 0,
    introDelay: 800,
    outroDelay: 900,
    props: (on) => ({
      opacity: on ? 1 : 0,
    }),
  });

  const TEXTURE = TransitionProps(isOn, {
    duration: 1000,
    introDelay: -600,
    outroDelay: 600,
    easing: "cubic-bezier(0.22, 1, 0.36, 1)",
    props: (on) => ({
      willChange: "unset",
      backgroundColor: "#000",
      top: on ? 0 : 110,
    }),
  });

  const ITEM_1_INTRO = TransitionProps(isOn, {
    duration: 1000,
    introDelay: -600,
    outroDelay: 600,
    easing: "cubic-bezier(0.22, 1, 0.36, 1)",
    props: (on) => ({
      backgroundColor: "#000",
      transform: `translateY(${on ? -110 : 110}%)`,
    }),
  });

  const ITEM_2_INTRO = TransitionProps(isOn, {
    duration: 2000,
    introDelay: -1500,
    outroDelay: 700,
    easing: "cubic-bezier(0.22, 1, 0.36, 1)",
    props: (on) => ({
      backgroundImage: `url("${uac}")`,
      backgroundColor: "#e7c314",
      backgroundSize: "auto 80%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      transform: `translateY(${on ? -110 : 110}%)`,
    }),
  });

  return {
    TEXTURE,
    ITEM_1_INTRO,
    ITEM_2_INTRO,
    BG,
    CONTENT,
  };
}

export default LowerThirds;
