import Timer from "@comps/timer/Timer";
import { Live, ReduxState } from "@defs/types";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const mcs = makeStyles({
  BODY: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 25px",
    flexGrow: 1,
  },
  TIMER: {
    fontFamily: `'CINZEL bold'`,
    fontSize: 50,
    color: "#2042e5",
  },
  TEXTS: {
    display: "flex",
    flexDirection: "column",
    textTransform: "uppercase",
    color: "#2e3030",
    "& .top": {
      fontSize: 14,
    },
    "& .bot": {
      fontFamily: `'CINZEL bold'`,
      fontSize: 30,
      fontWeight: 900,
    },
  },
});
const TimerComponent = () => {
  const CLASSES = mcs();
  const { countdown_minutes } = useSelector<ReduxState, Live>((s) => s.live);
  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.TEXTS}>
        <div className="top">Starting</div>
        <div className="bot">SOON</div>
      </div>
      <Timer
        className={CLASSES.TIMER}
        expiryTimestamp={countdown_minutes ?? Date.now()}
      />
    </div>
  );
};

export default TimerComponent;
