import { makeStyles } from "@material-ui/core";
import cardBig from "@img/card-big.png";
import { ReduxState, Live, ContainerMode } from "@defs/types";
import { useSelector } from "react-redux";
import { TransitionProps } from "@lib/CSStransition";
import Schedule from "@comps/contents/big_banner/Schedule";
import Standings from "@comps/contents/big_banner/Standings";
import LowerThirds from "./LowerThirds";
import TeamCard from "./TeamCard";
import PlayerVsPlayer from "@view/PlayerVsPlayer";

const mcs = makeStyles({
  BODY: {
    height: 577,
    width: 834,
    backgroundSize: "100% 100%",
    backgroundImage: `url("${cardBig}")`,
    position: "relative",
  },
  WRAPPER: {
    padding: 20,
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    zIndex: 5,
  },
});

const CardBig = () => {
  const CLASSES = mcs();
  const { container_mode = "schedule" } = useSelector<ReduxState, Live>(
    (s) => s.live
  );

  const {
    groupAStyles,
    onEnterStyles,
    scheduleStyles,
    groupBStyles,
    pvpStyles,
  } = getStyles(container_mode);

  return (
    <>
      <div className={CLASSES.BODY} style={onEnterStyles}>
        <div className={CLASSES.WRAPPER} style={scheduleStyles}>
          <Schedule />
        </div>
        <div className={CLASSES.WRAPPER} style={groupAStyles}>
          <Standings groupIndex={0} />
        </div>
        <div className={CLASSES.WRAPPER} style={groupBStyles}>
          <Standings groupIndex={1} />
        </div>
        <div className={CLASSES.WRAPPER} style={pvpStyles}>
          <PlayerVsPlayer />
        </div>
      </div>
      <LowerThirds />
    </>
  );
};

function getStyles(mode?: ContainerMode) {
  // <------------- ON ENTER ------------->
  const onEnter = !!mode;
  const onEnterStyles = TransitionProps(onEnter, {
    props: (on) => ({
      transform: `translateY(${on ? 0 : 10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- mode = "schedule" ------------->
  const isSchedule = mode === "schedule";
  const scheduleStyles = TransitionProps(isSchedule, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : -10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- mode = "PvP Stats" ------------->
  const isPvP = mode === "stats_player_vs";
  const pvpStyles = TransitionProps(isPvP, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : -10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- mode = "standings_group_a" ------------->
  const isGroupA = mode === "standings_group_a";
  const groupAStyles = TransitionProps(isGroupA, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : -10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  // <------------- mode = "standings_group_b" ------------->
  const isGroupB = mode === "standings_group_b";
  const groupBStyles = TransitionProps(isGroupB, {
    props: (on) => ({
      transform: `translateX(${on ? 0 : -10}px)`,
      opacity: on ? 1 : 0,
    }),
  });

  return {
    onEnterStyles,
    scheduleStyles,
    groupAStyles,
    groupBStyles,
    pvpStyles,
  };
}

export default CardBig;
