import useTournament from "@lib/useTournament";
import { makeStyles } from "@material-ui/core";
import React from "react";

import divider from "@img/divider_vertical.png";
import { Participant, ParticipantElement } from "@defs/types";
import Coin from "@comps/containers/Coin";

const mcs = makeStyles({
  BODY: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  SECTION: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: "0px 20px",
    position: "relative",
    maxWidth: 389.5,
  },
  DIVIDER: {
    height: 479,
    width: 15,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundImage: `url("${divider}")`,
  },
  LABEL: {
    position: "absolute",
    top: 20,
    right: 20,
    color: "#fff",
    opacity: 0.8,
    fontSize: "1.2em",
    padding: "12px 20px",
  },
});

interface Props {
  groupIndex: number;
}

const Standings = ({ groupIndex = 0 }: Props) => {
  const CLASSES = mcs();
  const {
    groupIds = [],
    getTeamByGroupId,
    getTeamGroupsResult,
  } = useTournament();

  const MAP = "ABCDEFGHIJKLMNOP";
  const CURRENT_GROUP = groupIds[groupIndex];
  const TEAMS = getTeamByGroupId(CURRENT_GROUP);

  const groupsSort = (a?: Participant, b?: Participant) => {
    const aPoints = getTeamGroupsResult(a?.id).points;
    const bPoints = getTeamGroupsResult(b?.id).points;
    return aPoints < bPoints ? 1 : -1;
  };

  const SORTED = TEAMS.sort(groupsSort);

  const LEFT = SORTED.filter((_, i) => i < SORTED.length / 2);
  const RIGHT = SORTED.filter((_, i) => i >= SORTED.length / 2);

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.LABEL}>GROUP {MAP[groupIndex]} STANDINGS</div>

      <div className={CLASSES.SECTION}>
        {LEFT.map((team, i) => (
          <Team team={team} key={i} num={i + 1} />
        ))}
      </div>
      <div className={CLASSES.DIVIDER}></div>
      <div className={CLASSES.SECTION}>
        {RIGHT.map((team, i) => (
          <Team team={team} key={i} num={i + 4} />
        ))}
      </div>
    </div>
  );
};

const teamStyles = makeStyles({
  BODY: {
    display: "flex",
    alignItems: "center",
  },
  NUMBER: {
    color: "#fff",
    fontFamily: `"FUTURA bold"`,
    margin: "0px 15px",
    fontSize: 35,
    lineHeight: 1,
  },
  TEXT: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    margin: "0px 10px",
    textTransform: "uppercase",
    flexGrow: 1,
    "& .CODE": {
      fontFamily: `"CINZEL bold"`,
      fontSize: 35,
    },
    "& .SHORTNAME": {
      color: "#fff",
    },
  },
  SCORE: {
    fontFamily: `"FUTURA bold"`,
    color: "#f5c70b",
    fontSize: 30,
    marginLeft: 10,
    textAlign: "right",
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
});

const Team = ({ team, num }: { team: Participant; num: number }) => {
  const CLASSES = teamStyles();

  const { getTeamGroupsResult } = useTournament();

  const LOGO = team?.logo ?? "";
  const BASE64 = team?.logo_base64;
  const CODE = team?.university_acronym;
  const SHORTNAME = team?.org_shortname;
  const COLOR = team?.badge_color;

  function SCORE() {
    const { wins, loses } = getTeamGroupsResult(team.id);
    return `${wins}-${loses}`;
  }

  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.NUMBER}>{num}</div>
      <Coin
        base64={BASE64}
        url={LOGO}
        style={{ height: 115, width: 115 }}
        shadow
        color={COLOR}
      />
      <div className={CLASSES.TEXT}>
        <div className="CODE">{CODE}</div>
        <div className="SHORTNAME">{SHORTNAME}</div>
      </div>
      <div className={CLASSES.SCORE}>{SCORE()}</div>
    </div>
  );
};

export default Standings;
