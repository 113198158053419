import { CSSProperties } from "react";

/**
 * # Transition Styles
 * ! <------
 * ## deprecated
 * ! ------>
 * @param toggle - boolean property
 * @param duration milliseconds
 * @param props function that takes `Boolean` as argument and returns css properties
 * @param introDelay addtional intro delay in milliseconds
 * @param easing check {@link https://easings.net}
 * @returns
 */
export const TS = (
  toggle: boolean,
  duration: number = 600,
  props: (isOn: boolean) => CSSProperties,
  introDelay: number = 0,
  outroDelay: number = 0,
  easing?: string
): CSSProperties => {
  return {
    willChange: Object.keys(props).join(","),
    transitionProperty: "all",
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: easing || "cubic-bezier(0.65, 0, 0.35, 1)",
    transitionDelay: `${toggle ? duration + introDelay : outroDelay}ms`,
    ...props(toggle),
  };
};

interface TransitionOptions {
  duration?: number;
  props(isOn: boolean): CSSProperties;
  introDelay?: number;
  outroDelay?: number;
  easing?: string;
}

/**
 * # Transition Properties
 * Uses {@link TransitionOptions} as options.
 * This is a more elegant solution than {@link TS}
 * @param toggle boolean
 * @param options {@link TransitionOptions}
 * @returns object {@link CSSProperties}
 */
export function TransitionProps(
  toggle: boolean,
  options: TransitionOptions
): CSSProperties {
  const {
    props,
    duration = 600,
    introDelay = 0,
    outroDelay = 0,
    easing = "cubic-bezier(0.65, 0, 0.35, 1)",
  } = options;
  return {
    willChange: Object.keys(options.props).join(","),
    transitionProperty: "all",
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: easing,
    transitionDelay: `${toggle ? duration + introDelay : outroDelay}ms`,
    ...props(toggle),
  };
}
