import { makeStyles } from "@material-ui/core";
import diamond from "@img/paper.jpg";
import React from "react";

interface CasterProps {
  socialLink?: string;
  screenName: string;
  flipX?: boolean;
}
const mcs = makeStyles<any, Partial<CasterProps> & { posY: number }>({
  BODY: {
    height: 33,
    width: 434,
    display: "flex",
    flexDirection: (props) => (props.flipX ? "row-reverse" : "row"),
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: `#f0c92b`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: "hidden",
    padding: "0px 30px 4px 30px",
    position: "relative",
    "&::after": {
      content: `''`,
      backgroundColor: "rgba(0,0,0,.1)",
      width: "100%",
      height: 4,
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    "&::before": {
      content: `''`,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      backgroundSize: "cover",
      backgroundImage: `url("${diamond}")`,
      transition: "all 0.6s cubic-bezier(0.65, 0, 0.35, 1)",
      backgroundPosition: (props) => `center ${props.posY}%`,
      opacity: 0.65,
      zIndex: 1,
      mixBlendMode: "hard-light",
    },
  },
  SOCIAL: {
    textTransform: "uppercase",
    fontSize: 12,
    lineHeight: 1,
  },
  SCREEN_NAME: {
    textTransform: "uppercase",
    fontFamily: `"CINZEL bold"`,
    fontSize: 24,
    lineHeight: 1,
  },
});

const TalentNameTag = (props: CasterProps) => {
  const { screenName, socialLink, flipX } = props;
  const CLASSES = mcs({ flipX, posY: Math.floor(Math.random() * 80) });
  return (
    <div className={CLASSES.BODY}>
      <div className={CLASSES.SOCIAL}>{socialLink}</div>
      <div className={CLASSES.SCREEN_NAME}>{screenName}</div>
    </div>
  );
};

export default TalentNameTag;
