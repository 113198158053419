import { Match, Participant } from "@defs/types";
import useTournament from "@lib/useTournament";
import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";
import outline from "@img/team_banner_outline.png";
import { TransitionProps } from "@lib/CSStransition";
import { useContext } from "react";
import { WindowContext } from "@lib/WindowContext";
import Coin from "./Coin";
import useAdjustments from "@lib/useAdjustments";
import { AdjustmentsProps } from "@defs/adjustments.interface";

const mcs = makeStyles<any, { posX: number }>({
  BODY: {
    height: 590 + 102,
    width: 390,
    position: "relative",
    backgroundColor: "#f0f0f0",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  INFO: {
    height: 102,
    width: 390,
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    padding: "0px 30px 0px 30px",

    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,

    "&::after": {
      content: `""`,
      position: "absolute",
      bottom: 0,
      left: 0,
      height: 8,
      width: "100%",
      backgroundColor: "rgba(0,0,0,.25)",
    },
  },
  DETAILS: {
    textTransform: "uppercase",
    flexGrow: 1,
    lineHeight: 1,
    color: "#333333",
    paddingRight: 15,
    "& .school": {
      fontSize: 14,
    },
    "& .org": {
      fontFamily: `"CINZEL bold"`,
      fontSize: 35,
      lineHeight: 0.9,
      letterSpacing: -1,
    },
  },
  SCORE: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    fontSize: 28,
    lineHeight: 1,
    color: "#004fff",
    fontFamily: `"FUTURA bold"`,
  },
  BANNER: {
    height: 590,
    width: 390,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",

    "& .banner": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      filter: `grayscale(40%) sepia(50%)`,
    },
    "& .player": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundPosition: "bottom center",
      backgroundRepeat: "no-repeat",
    },

    "& .outline": {
      height: 563,
      width: 354,
      backgroundSize: "100% 100%",
      backgroundImage: `url("${outline}")`,
      position: "absolute",
      zIndex: 20,
    },
  },
  COIN: {
    position: "absolute",
    top: 90,
    height: 241,
    width: 241,
    zIndex: 99,
  },
});

const TeamCard = ({
  TEAM,
  MATCH,
  DELAY,
  FLIPPED,
  PLAYER_IMAGE,
  IMAGE_SETTINGS,
}: {
  TEAM?: Participant;
  MATCH?: Match;
  DELAY?: number;
  FLIPPED?: boolean;
  PLAYER_IMAGE?: string;
  IMAGE_SETTINGS?: Partial<AdjustmentsProps>;
}) => {
  const CLASSES = mcs({ posX: Math.floor(Math.random() * 80) });
  const { getTeamGroupsResult, score } = useTournament();

  const SCHOOL = TEAM?.university_name;
  const ORG = TEAM?.org_shortname;
  const LOGO = TEAM?.logo;
  const BASE64 = TEAM?.logo_base64;
  const COLOR = TEAM?.badge_color;
  const IS_GROUPS = !!MATCH?.group_id;
  const SCORE = getScore();

  function getScore() {
    if (!MATCH) return "";
    if (IS_GROUPS) {
      const { wins, loses } = getTeamGroupsResult(TEAM?.id);
      return `${wins}-${loses}`;
    }
    const { wins } = score(MATCH, TEAM?.id);
    return wins;
  }

  // <------------ ANIMATION STUFF ----------->
  const isActive = useContext(WindowContext);
  const styles = getStyles(!!PLAYER_IMAGE && isActive && !!ORG, DELAY);
  const adjust = useAdjustments();

  return (
    <div className={CLASSES.BODY} style={styles.body}>
      <Coin
        url={LOGO}
        base64={BASE64}
        color={COLOR}
        // shadow
        className={CLASSES.COIN}
        style={{
          ...(FLIPPED ? { left: -144 } : { right: -144 }),
          ...styles.coin,
        }}
      />
      <div className={CLASSES.BANNER}>
        <div
          className="banner"
          style={{
            backgroundImage: `url("${
              TEAM?.university_banner_base64 || TEAM?.university_banner
            }")`,
            ...styles.bg,
          }}
        ></div>
        <div style={{ height: "100%", width: "100%", ...styles.photo }}>
          <div
            className="player"
            style={{
              backgroundImage: `url("${PLAYER_IMAGE}")`,
              transformOrigin: "bottom center",
              ...adjust(IMAGE_SETTINGS),
            }}
          ></div>
        </div>
        <div className="outline"></div>
      </div>
      <div className={CLASSES.INFO}>
        <div className={CLASSES.DETAILS}>
          <div className="school">{SCHOOL}</div>
          <div className="org">{ORG}</div>
        </div>
        <div className={CLASSES.SCORE}>{SCORE}</div>
      </div>
    </div>
  );
};

function getStyles(
  toggle: boolean = false,
  delay?: number
): { [key: string]: CSSProperties } {
  const bg = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      transitionProperty: "transform",
      transform: `scale(${isOn ? 1 : 1.4})`,
    }),
  });

  const photo = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      transitionProperty: "transform",
      transformOrigin: "bottom center",
      transform: `scale(${isOn ? 1 : 0.93})`,
    }),
  });

  const body = TransitionProps(toggle, {
    duration: 600,
    introDelay: delay,
    props: (isOn) => ({
      opacity: isOn ? 1 : 0,
      transform: `translateY(${isOn ? 0 : -20}px)`,
    }),
  });

  const coin = TransitionProps(toggle, {
    duration: 1500,
    introDelay: delay,
    props: (isOn) => ({
      opacity: isOn ? 1 : 0,
      transform: `
        scale(${isOn ? 1 : 1.2})
        rotate(${isOn ? 0 : 360}deg)
      `,
    }),
  });

  return { bg, body, coin, photo };
}
export default TeamCard;
