import LowerThirds from "@comps/containers/LowerThirds";
import React from "react";

const LowerThirdsPreview = () => {
  return (
    <div
      style={{
        height: 1080,
        width: 1920,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <LowerThirds hasTransitions />
      </div>
    </div>
  );
};

export default LowerThirdsPreview;
